export default {
  // 新的 services 条目
  attachYourMembershipID: 'When contacting SmoothTravel HQ in Shanghai, members are kindly requested to include their Member ID in the subject line for authentication purposes.',
  serviceTips: 'Click on any heading below for further details.',
  serviceMainEntry1: 'Activate new top-tier outbound China travel partners',
  serviceMainEntry2: 'Minimize your China business risk',
  serviceMainEntry3: 'Nurture your China travel industry relationships',
  serviceMainEntry4: 'Refine your China strategy',
  serviceMainEntry5: 'Key Stakeholders',
  // serviceEntry1: 'China Partner Search',
  serviceEntry1: 'Partner Matchmaking',
  serviceEntryContent1: "SmoothTravel provides overseas members with the ability to identify new inbound or outbound China travel trade partners, and expand your China business with ideally suited new partners. To identify the ideal Chinese travel trade partner which meets your requirements, please contact our Member Matching Manager at <a href='mailto:membermatching{'@'}smoothtravel.com'>Membermatching{'@'}smoothtravel.com</a> and we will introduce you to the most highly-qualified China travel trade which best meet your requirements.",
  "serviceEntryContent1-1": "SmoothTravel's search engine allows members to search for potential partners at any time by keyword, location, or business type. Click <a href='/#/Search' style='color: #0000ff; text-decoration: underline;'>here</a> to start searching and effortlessly connect with other members directly on our platform, streamlining your partner discovery and collaboration process.",
  serviceEntry2: 'Sales material translation into Chinese if needed',
  serviceEntryContent2: "SmoothTravel’s member engagement manager will help you evaluate whether your sales materials are suitable for use in the China market to attract China trade and establish new working partnerships. If needed, SmoothTravel is able to provide translation support at cost using a team of translators specializing in developing sales materials for the China inbound and outbound travel markets. Ensuring accurate and culturally relevant Chinese translations of your sales materials and online content which cater to the needs and nuances of the China travel market will be a key to boosting your China business. To have your online or offline sales materials evaluated at no cost, please contact your China Member Engagement Manager at <a href='mailto:memberengagement{'@'}smoothtravel.com'>Memberengagement{'@'}smoothtravel.com</a>.",
  serviceEntry3: 'Chinese member sales material evaluation',
  // serviceEntryContent3: "After selecting potential target travel trade partners, SmoothTravel provides overseas members with online access to comprehensive information about potential new Chinese partners. Through our user-friendly interface, overseas members can effortlessly discover a wealth of English materials posted by Chinese members. These materials in most cases include detailed company profiles, product catalogues, marketing presentations, business conditions etc. Additionally, overseas members can view client testimonials and case studies to be able to make an informed decision whether to initiate dialogue and establish a potential collaboration with any given Chinese partner.",
  serviceEntryContent3: "After selecting potential target travel trade partners, SmoothTravel provides overseas members with online access to comprehensive information about potential new Chinese partners. Through our user-friendly interface, overseas members can effortlessly discover a wealth of English materials posted by Chinese members. These materials in most cases include detailed company profiles, product catalogues, marketing presentations, business conditions etc. Additionally, overseas members can view client testimonials and case studies to be able to make an informed decision whether to initiate dialogue and establish a potential collaboration with any given Chinese partner.If you would like to have your China sales materials evaluated at no cost, please E-mail copies of your China materials to <a href='mailto:memberengagement{'@'}smoothtravel.com'>Memberengagement{'@'}smoothtravel.com</a>, and our team will evaluate your materials before showcasing them in your profile for evaluation by the Chinese travel trade.",
  serviceEntry4: 'Support from your SmoothTravel China Member Engagement Manager',
  serviceEntryContent4: "As a SmoothTravel member, you will be assigned a China Member Engagement Manager who will lead you through the above process and work with you to ensure you are able to forge new working partnerships in China and grow your China revenues rapidly but securely. To be put in touch with your China Member Engagement Manager, contact <a href='mailto:memberengagement{'@'}smoothtravel.com'>Memberengagement{'@'}smoothtravel.com</a>",
  serviceEntry5: 'Payment guarantee system',
  serviceEntryContent5: "To minimize payment risk in dealing with new Chinese partners, SmoothTravel has partnered with Lianlian Gloabal (<span id='563315389778378754' class='seeKeyStakeholders' style='color: #0000ff; text-decoration: underline; cursor: pointer;'>see profile here</span>) to provide members with a unique payment guarantee system which bridges the gap between the desire of Chinese travel trade to pay overseas suppliers at the conclusion of a tour, versus the desire of overseas members to receive pre-payment. Lianlian Gloabal’s payment guarantee system effectively eliminates all payment risk in both China as well as overseas. To Use LianLian Pay：<a href='https://global.lianlianpay.com/?inviteCode=3B2VLG' target='_blank' style='color: #0000ff; text-decoration: underline'>Click here</a>.",
  serviceEntry6: 'Bilingual dispute arbitration',
  serviceEntryContent6: "In case of any disagreement between overseas and Chinese members, SmoothTravel has partnered with the Shanghai Government’s specialized travel dispute resolution center (<span id='603523492931014656' class='seeKeyStakeholders' style='color: #0000ff; text-decoration: underline; cursor: pointer;'>see profile here</span>) to provide access to bilingual arbitrators specializing in resolving cross-border travel disputes, and in a unique position to provide a reliable arbitration mechanism at no cost to either party which navigates cultural and linguistic differences, and results in a fair and effective resolution of any conflict. To access SmoothTravel’s dispute resolution platform, email <a href='mailto:arbitration{'@'}smoothtravel.com'>Arbitration{'@'}smoothtravel.com</a>.",
  serviceEntry7: 'China outbound travel insurance',
  serviceEntryContent7: "SmoothTravel has partnered with China Pacific Insurance “CPIC” (<span id='534690179236065280' class='seeKeyStakeholders' style='color: #0000ff; text-decoration: underline; cursor: pointer;'>see profile here</span>) to develop China’s first ever comprehensive outbound travel insurance coverage specifically tailored for Chinese visitors, offering a safety net which protects the interests of Chinese travelers, Chinese outbound travel trade and overseas partners. In the event of an accident, most costs will be covered, reducing the financial risk of Chinese and Overseas members. To learn more about SmoothTravel’s insurance program with CPIC and to encourage your Chinese outbound travel trade partners to subscribe, contact <a href='mailto:insurance{'@'}smoothtravel.com'>Insurance{'@'}smoothtravel.com</a>.",
  serviceEntry8: 'Member evaluation/feedback loop',
  serviceEntryContent8: "SmoothTravel is the brainchild of the Shanghai Government’s Travel Quality Assurance Center (<span id='603523492931014656' class='seeKeyStakeholders' style='color: #0000ff; text-decoration: underline; cursor: pointer;'>see profile here</span>) who’s ultimate goal is to increase the quality of cross-border travel between China and the world, and to allow top quality overseas members to partner with the best that China’s travel industry has to offer. The core goals of SmoothTravel are to provide a reciprocal feedback mechanism that drives continuous service improvement in China and overseas, to ensure the highest quality Chinese travel industry players partner with the right overseas members, and to elevate the overall quality of overseas travel offerings in China. In order to provide feedback regarding a Chinese member, please contact <a href='mailto:feedback{'@'}smoothtravel.com'>Feedback{'@'}smoothtravel.com</a> for next steps.",
  serviceEntry9: 'Showcase of top service quality members',
  serviceEntryContent9: "SmoothTravel together with the Shanghai Government will publish an annual list of outstanding global players in the travel industry in various fields, recommended and selected by Chinese members for their exceptional quality. This showcase will evaluate service quality, professional capabilities, and customer satisfaction in the eyes of the China market. Similarly, top quality Chinese outbound and inbound travel agents will be showcased for overseas members based on the evaluations of overseas members. In order to view the current quality rankings, please click <a href='/#/rankinglist' target='_blank' style='color: #0000ff; text-decoration: underline;'>here</a>.",
  serviceEntry10: 'China Expert Training',
  serviceEntryContent10: "SmoothTravel offers a comprehensive two-way training platform. Overseas members can utilize this platform to train Chinese travel trade partners to become experts regarding their destination, services, or products. Likewise, SmoothTravel provides Chinese members with a platform to train overseas travel trade partners to become experts in catering to inbound Chinese tourists. In short, SmoothTravel's platform facilitates specialized training sessions tailored for both overseas and Chinese members. To access SmoothTravel’s training options or to develop your own training program for Chinese travel trade and to understand the training options and cost structure, please contact <a href='mailto:chinatraining{'@'}smoothtravel.com'>Chinatraining{'@'}smoothtravel.com</a>",
  serviceEntry11: 'Event Promotion targeting China’s outbound travel industry',
  serviceEntryContent11: "Overseas members are able to organize promotional events and campaigns targeting Chinese Travel Trade, including training seminars, workshops, roadshows, trade shows, and trade product announcements via SmoothTravel. Contact <a href='mailto:chinaevents{'@'}smoothtravel.com'>Chinaevents{'@'}smoothtravel.com</a> to discuss your requirements.",
  serviceEntry12: 'SmoothTravel Logo/seal of approval on China marketing materials',
  // serviceEntryContent12: "SmoothTravel is the official travel portal of the Shanghai Government, linking East China’s travel industry with travel entities around the world. Authorized overseas members are able to place the SmoothTravel logo on their China industry marketing materials as a stamp of approval from the Shanghai Government. In order to apply for authorization to use the SmoothTravel Logo on your China industry marketing materials, contact your China Member Engagement Manager at <a href='mailto:memberengagement{'@'}smoothtravel.com'>memberengagement{'@'}smoothtravel.com</a>.",
  serviceEntryContent12: "SmoothTravel is the official travel portal of the Shanghai Government, linking East China’s travel industry with travel entities around the world. If desired, SmoothTravel members are able to use the SmoothTravel logo in their promotional materials for non-commercial purposes to indicate to partners that they have access to the unique services of SmoothTravel and as a form of accreditation in the China market. This right of use, however, may be withdrawn by SmoothTravel at any time.",
  serviceEntry13: 'China outbound market and consumer insights',
  serviceEntryContent13: "SmoothTravel leverages extensive China government and industry resources to develop the latest China outbound travel market research, industry trends, and consumer tastes and preferences. SmoothTravel conducts surveys, in-depth interviews, focus group discussions, workshops, and on-site visits in order to produce updates on the state of China’s outbound travel markets and the perceptions of Chinese outbound travelers. The goal is to provide you with insights into the state of China’s outbound travel market, delivering data and analysis to help you leverage China market demand, changes, and dynamics.",
  serviceEntryContent13Add: "To access the latest China outbound market and consumer insights, click <a href='/#/ChinaTourismMarketAndConsumerInsights' target='_blank' style='color: #0000ff; text-decoration: underline;'>here</a>.",
  serviceEntry14: 'Custom China market research',
  serviceEntryContent14: "Overseas members of SmoothTravel are able to commission custom China outbound travel market research, as needed, at cost. Custom China market research can encompass brand awareness surveys, market share analysis, identification of key market players, product channel analysis and recommendations, competitor analysis, consumer segment identification, price analysis, strategic planning, and digital consultancy, among others. Our ultimate goal is to help you seize China outbound travel market opportunities, formulate targeted China marketing strategies, and assist you to identify the right partners in the China market. To commission custom research in the China market, contact <a href='mailto:research{'@'}smoothtravel.com'>Research{'@'}smoothtravel.com</a>",

  viewWebsite: 'View website',

  /**
   * 服务条例
   */
  termsOfService: 'Terms of Service',
  acceptanceOfTerms: 'Acceptance of Terms',
  acceptanceOfTermsOne: 'Whether as a visitor or a registered user, these terms of service are jointly entered into by you and www.SmoothTravel.com ("SmoothTravel," "we," or "our"). This includes the following terms and conditions, along with any explicitly referenced documents (collectively referred to as the "Terms of Service"). These govern your access to and use of www.SmoothTravel.com and related domains, including any content, features, and services provided through www.SmoothTravel.com (the "Website").',
  acceptanceOfTermsTwo: 'Before using the Website, please carefully read the Terms of Service. By using the Website or clicking to accept or agree to the Terms of Service when available, you indicate that you accept and agree to be bound by these Terms of Service. If you do not wish to agree to these Terms of Service, you may not access or use the Website.',
  acceptanceOfTermsThree: 'This Website is intended for use by users who are at least 18 years old or the applicable legal age in your place of residence. By using this Website, you declare and warrant that you have reached the legal age required to enter into binding contracts with SmoothTravel. If you do not meet this requirement, you may not access or use this Website.',

  softwareLicenseAndUserServiceAgreement: 'SmoothTravel Platform Software License and User Service Agreement',
  softwareLicenseAndUserServiceAgreementOne: '[Careful Reading] Before clicking to agree to this agreement during the registration process, you should carefully read this agreement. Please carefully read and fully understand the content of each clause, especially the clauses that exempt or limit liability, specify applicable laws, and address dispute resolution. Key clauses will be identified in bold, and you should read them carefully. If you have any questions about the agreement, you can inquire through the contact information provided in this agreement.',
  softwareLicenseAndUserServiceAgreementTwo: '[Contract Approval] When you follow the prompts on the registration page to fill in information, read and agree to this agreement, and complete the entire registration process, it indicates that you have fully read, understood, and accepted all the contents of this agreement, becoming a user of SmoothTravel. During the process of reading this agreement, if you do not agree to this agreement or any of its terms, you should immediately cease the registration process.',
  softwareLicenseAndUserServiceAgreementThree: '[Confirmation and Acceptance of Service Agreement] SmoothTravel is guided by the Shanghai Cultural and Tourism Quality Service and Monitoring Center and operated by AVIAREPS China LTD. If it involves specific products and/or services (hereinafter referred to as "Products/Services"), they will be provided by qualified service providers. If you, the user (hereinafter referred to as "You"), access the platform or use the products or services provided by SmoothTravel, it is deemed that you have accepted the "SmoothTravel Platform User Service Agreement" (hereinafter referred to as "this Agreement"). Please read the following content carefully, especially the bolded text below. If you do not agree to any of the following content, please immediately stop accessing the SmoothTravel Platform or using any products or services provided by SmoothTravel.',

  generalProvisions: '1. General Provisions',
  generalProvisionsOne: 'This agreement, including but not limited to reservation notices, travel contracts, account cancellation agreements, and other agreements (hereinafter referred to as "Other Terms"). In the event of any inconsistency between this agreement and the "Other Terms," the "Other Terms" shall prevail. Unless explicitly stated otherwise, any products/services provided by SmoothTravel are bound by this agreement.',
  generalProvisionsTwo: 'In this agreement, "User" refers to companies/businesses that access or use the products/services provided by the SmoothTravel Platform, including but not limited to registered and unregistered users. Users who have not registered on the SmoothTravel Platform become our "Non-Registered Users" from the moment they start using the SmoothTravel Platform\'s products and/or services. Non-registered users must adhere to all terms in this agreement, excluding exclusive provisions for registered users.',
  generalProvisionsThree: 'If you, as a user of SmoothTravel or a collaborating company, access the SmoothTravel Platform or use SmoothTravel products/services, it is deemed that you agree to all the terms of this agreement, as well as other terms, rules, explanations, and operational guidelines published by the SmoothTravel Platform.',

  serviceNotices: '2. Service Notices',
  serviceNoticesOne: 'The SmoothTravel Platform utilizes its operating system through the international internet to provide users with various products/services. You must:',
  serviceNoticesTwo: '1)Independently configure necessary internet devices, including PCs, mobile phones, tablets, as well as modems, routers, etc.',
  serviceNoticesThree: '2)Personally bear internet and payment-related network expenses and telephone charges.',
  serviceNoticesFour: 'Given the significance of the products/services provided by the SmoothTravel Platform, you agree to:',
  serviceNoticesFive: '1)Provide true, detailed, and accurate company/personal information for registering a SmoothTravel Platform account, and not use others\' email addresses, phone numbers, or register accounts in a manner that impersonates others.',
  serviceNoticesSix: '2)After submitting registration information, promptly update it in the event of any changes. If the information you provide does not meet legal, truthful, accurate, and comprehensive requirements, you shall bear corresponding responsibilities and consequences. The SmoothTravel Platform also reserves the right to terminate your use of various products/services.',

  modificationOfTheServiceAgreement: '3. Modification of the Service Agreement',
  modificationOfTheServiceAgreementOne: 'SmoothTravel reserves the right to modify this agreement and/or various rules when necessary. Advance notice of such modifications will be provided on the corresponding pages of the SmoothTravel Platform. Specifically, it will announce or notify you of any changes to this agreement through appropriate means on the platform. These notifications are considered delivered to you on the date of publication or sending.',
  modificationOfTheServiceAgreementTwo: 'SmoothTravel assures that, for modifications to this agreement and/or various rules, it will announce or notify at least seven days prior to the effective date of the revised text. If you disagree with the changes, you should immediately cease accessing the SmoothTravel Platform or using the products/services provided by it. Continuing to use the SmoothTravel Platform\'s products/services after the effective date of the changes to this agreement and/or various rules indicates that you accept the revised text.',

  serviceChangesInterruptionsAndTermination: '4. Service Changes, Interruptions, and Termination',
  serviceChangesInterruptionsAndTerminationOne: 'You fully understand and agree that this service involves internet and mobile communication services, subject to potential disruptions from various unstable factors. Therefore, SmoothTravel is not liable for compensating any risks, losses, or service interruptions caused by force majeure, computer viruses, hacker attacks, system instability, user location, user shutdown, GSM networks, internet, communication lines, or other factors beyond SmoothTravel\'s control.',
  serviceChangesInterruptionsAndTerminationTwo: 'The SmoothTravel Platform may need to conduct regular or irregular inspections or maintenance on the platform or related equipment providing network services. In the event of service interruptions (including fee-based network services) within a reasonable time due to such circumstances, SmoothTravel is not obligated to assume any responsibility. However, SmoothTravel will make efforts to provide prior notice whenever feasible.',
  serviceChangesInterruptionsAndTerminationThree: 'You fully understand and agree that, unless otherwise specified in this service agreement, given the unique nature of network services, the SmoothTravel Platform may modify, interrupt, or terminate part or all of the network services. SmoothTravel is not obliged to bear responsibility for changes, interruptions, or service termination, except in cases where you have already ordered or applied for products/services through the SmoothTravel Platform, completed payment, and received confirmation of the order or application. SmoothTravel will make efforts to provide advance notice and reserve time for you to address related rights and interests whenever possible.',

  userCodeOfConduct: '5. User Code of Conduct',
  userCodeOfConductOne: 'When utilizing SmoothTravel Platform products/services, you must adhere to the relevant laws and regulations of the People\'s Republic of China. You undertake not to engage in any illegal or improper activities using SmoothTravel Platform products/services, including but not limited to the following behaviors:',
  userCodeOfConductTwo: '1. Uploading, displaying, posting, disseminating, or otherwise transmitting information containing any of the following content:',
  userCodeOfConductThree: '• Opposing the basic principles established by the Constitution.',
  userCodeOfConductFour: '• Endangering national security, leaking state secrets, subverting state power, undermining national unity.',
  userCodeOfConductFive: '• Harming the honor and interests of the country.',
  userCodeOfConductSix: '• Inciting ethnic hatred, ethnic discrimination, and undermining ethnic unity.',
  userCodeOfConductSeven: '• Undermining the state\'s religious policies, propagating cults and feudal superstitions.',
  userCodeOfConductEight: '• Spreading rumors, disrupting social order, and undermining social stability.',
  userCodeOfConductNine: '• Disseminating obscene, pornographic, gambling, violent, murderous, terrorist, or inciting criminal content.',
  userCodeOfConductTen: '• Insulting or defaming others, infringing on the legitimate rights of others.',
  userCodeOfConductEleven: '• Containing false, harmful, coercive, infringing on the privacy of others, harassing, infringing, slandering, vulgar, obscene, or other morally offensive content.',
  userCodeOfConductTwelve: '• Containing content restricted or prohibited by Chinese laws, regulations, rules, ordinances, and any other normative documents with legal effect.',

  illegal: '2. Accessing the SmoothTravel Platform or using SmoothTravel products/services for any illegal purpose or engaging in the following activities without permission:',
  illegalOne: '• Unauthorized access to computer information networks or illegal acquisition and use of computer information network resources.',
  illegalTwo: '• Unauthorized deletion, modification, or addition to the functions of computer information networks.',
  illegalThree: '• Unauthorized deletion, modification, or addition to data, and applications stored, processed, or transmitted in computer information networks.',
  illegalFour: '• Deliberately creating and spreading destructive programs such as computer viruses that may harm the security of computer information networks.',
  illegalFive: '• Other actions that may endanger the security of computer information networks.',
  illegalSix: '• Unauthorized commercial use of any data on the SmoothTravel Platform, including but not limited to acquiring, using, or assisting any third party in acquiring or using information displayed on the SmoothTravel Platform through web crawlers, copying, or other means, without prior written consent from SmoothTravel, is strictly prohibited.',
  illegalSeven: '• Using any device, software, or routine to interfere or attempt to interfere with the normal operation of the SmoothTravel Platform or any transaction or activity taking place on the SmoothTravel Platform, or taking any action that may impose an unreasonable or disproportionately large data load on the SmoothTravel Platform\'s network equipment.',
  illegalEight: '• Engaging in money laundering, stealing business secrets, stealing personal information, engaging in illegal cash withdrawal, forging SmoothTravel to publish any false or fraudulent information, and other illegal and irregular activities.',
  illegalNine: '• Engaging in activities unrelated to online transactions, such as posting advertising information (except with written permission from SmoothTravel).',
  illegalTen: '• Publishing any information that violates national laws, regulations, rules, ordinances, and other normative documents (including but not limited to the "Regulations on the Management of Internet Audio-Visual Program Services," "Regulations on the Governance of Internet Information Content," etc.) and this agreement.',

  disruptingPlatformTransactionOrder: '3. Disrupting platform transaction order, including but not limited to:',
  disruptingPlatformTransactionOrderOne: '• Purchasing services/products beyond real-life consumption needs.',
  disruptingPlatformTransactionOrderTwo: '• Engaging in unfair transactions or activities, such as using plugins, cheats, and other tools.',
  disruptingPlatformTransactionOrderThree: '• Engaging in unfair competition that violates the principles of honesty and credit, such as brushing orders and speculation.',
  disruptingPlatformTransactionOrderFour: '• Maliciously placing orders, maliciously safeguarding rights, false transactions, etc.',
  disruptingPlatformTransactionOrderFive: 'The occurrence of the above behaviors will be considered a fundamental breach of this agreement. In any case, if SmoothTravel Platform reasonably believes that you have engaged in or may engage in the above behaviors, SmoothTravel Platform may, at any time and without prior notice, delete any information spread or transmitted by you and suspend or terminate the provision of SmoothTravel Platform\'s products/services to you. You shall bear legal responsibility for your behavior in accessing the SmoothTravel Platform or using SmoothTravel Platform\'s products/services. If you spread or transmit information that violates national laws, regulations, public order, and good customs on the SmoothTravel Platform, the system records of SmoothTravel Platform may be provided to relevant authorities as evidence of your illegal activities.',

  iolating: '4. Violating any applicable national laws or international regulations in any way (including but not limited to laws involving the transfer or export of data, personally identifiable information, or software to China or other countries).',
  iolatingOne: '• Using any means to expose minors to inappropriate content or to exploit, harm, or attempt to exploit or harm minors.',
  iolatingTwo: '• Sending, receiving, uploading, downloading, using, or reusing any materials that do not comply with the content standards specified in these terms of service.',
  iolatingThree: '• Transmitting or obtaining any advertisements or promotional materials without our prior written consent, including any "junk mail," "chain letter," or "spam" or any other similar requests.',
  iolatingFour: '• Impersonating or attempting to impersonate SmoothTravel, SmoothTravel employees, other users, or any other individual or entity (including but not limited to using email addresses or screen names associated with any of the above content).',
  iolatingFive: '• Engaging in any false, fraudulent, or speculative bookings or reservations, or making any bookings or reservations in anticipation of demand.',
  iolatingSix: '• Engaging in any other acts that restrict or obstruct anyone from using or enjoying this website, or which, in our judgment, may harm SmoothTravel or the users of this website or make them liable.',

  agreeNotTo: 'Additionally, you agree not to:',
  agreeNotToOne: '• Use this website in any way that could disable, overload, damage, or impair the website, or interfere with any other party\'s use of the website, including their ability to engage in real-time activities through the website.',
  agreeNotToTwo: '• Use any robot, spider, or other automatic device, process, or means to access this website, including monitoring or copying any material on this website.',
  agreeNotToThree: '• Do not use any manual process to monitor or copy any material on this website, or use it for any unauthorized purpose without our prior written consent.',
  agreeNotToFour: '• Use any manual process to monitor or copy any material on this website, or use any device, software, or program to interfere with the normal operation of this website, without our prior written consent for any unauthorized purpose.',
  agreeNotToFive: '• Introduce any viruses, Trojan horses, worms, logic bombs, or other malicious or technologically harmful substances.',
  agreeNotToSix: '• Attempt to gain unauthorized access, interfere with, damage, or disrupt any part of this website, the server on which this website is stored, or any server, computer, or database connected to this website.',
  agreeNotToSeven: '• Carry out a denial-of-service attack or a distributed denial-of-service attack on this website.',
  agreeNotToEight: '• Engage in any other actions that attempt to interfere with the normal operation of this website.',

  responsibleFor: 'You are responsible for:',
  responsibleForOne: '• Making all arrangements necessary for you to have access to this website.',
  responsibleForTwo: '• Ensuring that all persons who access this website through your internet connection are aware of these terms of service and comply with them.',

  requiredTo: 'You are required to:',
  requiredToOne: '• Make all arrangements necessary for you to have access to this website.',
  requiredToTwo: '• Ensure that all persons who access this website through your internet connection are aware of these terms of service and comply with them.',

  userCodeOfConductThirteen: 'To access this website or specific resources it provides, you may be required to provide certain registration details or other information. One of the conditions for using this website is that all information provided must be correct, current, and complete. You agree that all actions we take regarding your information will comply with our privacy policy, and you agree to be bound by our privacy policy governing all actions taken with your information.',
  userCodeOfConductFourteen: 'If you choose or are provided with a username, password, or any other security procedure as part of our security protocols, you must treat such information as confidential and refrain from disclosing it to any other person or entity. You acknowledge that your account is personal, and you agree not to grant access to your username, password, or other security information to any other individual. You agree to promptly notify us of any unauthorized use or other security vulnerabilities related to your username or password. Additionally, you agree to ensure that you log out of your account at the end of each session. Regardless of whether you have obtained permission, any actions taken using your account (including user contributions) are your responsibility. Exercise caution when accessing your account in public places or on shared computers to prevent others from viewing or recording your password or other personal information.',
  userCodeOfConductFifteen: 'We reserve the right to suspend or terminate any account and/or disable any username, password, or other identifier at our sole discretion, whether chosen by you or provided by us, if, in our opinion, you violate any provisions of these terms of service.',

  intellectualPropertyRights: '6. Intellectual Property Rights',
  intellectualPropertyRightsOne: 'The intellectual property rights of the content generated or used by SmoothTravel in the products/services provided on the platform (including but not limited to products, software, web pages, text, images, audio, video, data, information, etc.) belong to SmoothTravel. SmoothTravel retains all intellectual property rights, and no transfer of rights occurs due to your usage. Without prior written consent from SmoothTravel, you are not allowed to use or create derivative works of the aforementioned content in any form, except when you are the rights holder of the work, have obtained authorization from relevant rights holders, or have a separate agreement with SmoothTravel.',
  intellectualPropertyRightsTwo: 'You undertake that the content (including but not limited to text, images, videos, audio, etc.) you publish or upload through the SmoothTravel platform is original or legally authorized, and the intellectual property rights of the uploaded content belong to you or the relevant rights holders. You are solely responsible for the content you upload and publish on the SmoothTravel platform. If the content you send or disseminate violates laws or regulations or infringes upon the rights of others, SmoothTravel has the right to independently judge and take measures such as deletion, blocking, or disconnection. In case of disputes arising from infringement of others\' intellectual property rights, you shall bear all responsibilities. If your actions cause losses to SmoothTravel, SmoothTravel has the right to demand compensation in accordance with legal provisions.',
  intellectualPropertyRightsThree: 'You undertake to irrevocably grant SmoothTravel or a third party designated by SmoothTravel a global, free, non-exclusive, and sublicensable right (including but not limited to the rights to copy, translate, compile, disseminate through information networks, adapt, create derivative works, perform, display, broadcast, shoot, distribute, as well as rights related to commercial development, promotion, and advertising) regarding the content (including but not limited to products, software, web pages, text, images, audio, video, data, information, etc.) you upload, publish, or transmit through the SmoothTravel platform. SmoothTravel\'s use of the content includes, but is not limited to, on the SmoothTravel platform or other websites, applications, products, or terminal devices designated by SmoothTravel.',
  intellectualPropertyRightsFour: 'You acknowledge and agree to authorize SmoothTravel, in its own name or through professional third parties designated by SmoothTravel, to undertake intellectual property rights protection for content that infringes upon your uploaded and published intellectual property rights. Methods of protection include but are not limited to monitoring infringement, sending cease and desist letters, initiating lawsuits or arbitration, mediation, settlement, etc. SmoothTravel has the right to make decisions and independently implement actions regarding intellectual property rights protection.',
  intellectualPropertyRightsFive: 'This website, along with all its content, features, and characteristics (including but not limited to all information, software, text, displays, images, videos, and audio, as well as the design, selection, and arrangement), belongs to SmoothTravel, its licensors, or other suppliers providing such materials, and is protected by Chinese and international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.',
  intellectualPropertyRightsSix: 'These terms of service allow you to use this website in the following ways: If you are a representative of SmoothTravel Member Agent ("Member Agent") or SmoothTravel Preferred Supplier ("Preferred Supplier"), you may use the website for the lawful business purposes expected by Member Agents and/or Preferred Suppliers (collectively referred to as "Permitted Use"). Unless complying with the following provisions, you may not copy, distribute, modify, create derivative works, publicly display, perform, republish, download, store, or transmit any material on our website:',
  intellectualPropertyRightsSeven: '• You may store files that are automatically cached by your web browser for display enhancement.',
  intellectualPropertyRightsEight: '• You may print or download a reasonable number of pages for Permitted Use only, and further copying, publishing, or distribution is prohibited.',
  intellectualPropertyRightsNine: '• If we provide desktop, mobile, or other applications for download, you may download a single copy onto a computer or mobile device, solely for Permitted Use, and agree to be bound by the end-user license agreement of that application.',
  intellectualPropertyRightsTen: '• If we provide social media features for certain content, you may take actions enabled by these features.',

  notAllowedTo: 'You are not allowed to:',
  notAllowedToOne: '• Modify any copies of materials obtained from this website.',
  notAllowedToTwo: '• Use any illustrations, photographs, videos, or audio sequences or any graphics.',
  notAllowedToThree: '• Delete or alter any copyright, trademark, or other proprietary notices on copies of materials from this website.',
  notAllowedToFour: 'Additionally, please note that certain Preferred Suppliers have provided images in the image library located on the product detail pages ("Supplier Images"), and these Preferred Suppliers have expressly agreed with SmoothTravel that Member Agents may use, copy, and distribute Supplier Images only when Member Agents are lawfully and accurately marketing and advertising their goods and services, and/or the relevant goods and services of the Preferred Suppliers are involved. Except as provided above, Supplier Images or any other images, graphics, illustrations, or photos on this website (including but not limited to SmoothTravel photos or user contributions) must not be used for any purpose. Unauthorized use of Supplier Images or any other images, graphics, illustrations, or photos on this website may subject you to legal consequences, including but not limited to claims, lawsuits, damages, legal fees, and civil and criminal penalties. If a Member Agent is unsure whether a specific image on the website is a Supplier Image, the Member Agent is responsible for verifying with their SmoothTravel marketing contact.',
  notAllowedToFive: 'Please note that we do not select or endorse Supplier Images, make no warranties regarding their ownership, and explicitly disclaim any responsibility to investigate or verify the ownership or usage rights of Supplier Images or any other images, graphics, illustrations, or photos on this website. You expressly waive and agree to indemnify, defend, and hold SmoothTravel harmless from any claims, damages, liabilities, costs, fees, or expenses (including but not limited to any claims of infringement of third-party proprietary rights) related to your use, copying, or distribution of Supplier Images or any other images, graphics, illustrations, or photos on this website.',
  notAllowedToSix: 'If you print, copy, modify, download, or use any part of the website in violation of the terms of service or provide access to others in a manner that violates the terms of service, SmoothTravel may terminate your right to use the website immediately, and you must, at our option, return or destroy any copies of the materials you have made. You will not transfer any rights, ownership, or interests in the website or any content on the website, and all rights not expressly granted in these terms of service are reserved by SmoothTravel. Any use not expressly authorized by these terms of service is a breach of these terms of service and may violate copyright, trademark, and other laws.',

  userContributions: '7. User contributions',
  userContributionsOne: 'This website may include interactive services, such as message boards, chat rooms, personal web pages or profiles, forums, bulletin boards, functions for saving and sharing travel services, interactive features for rating and commenting, surveys, contests, promotions, and other interactive services (collectively referred to as "Interactive Services"). These services enable users to post, submit, publish, display, or transmit (hereinafter "post") content or materials (collectively referred to as "User Contributions") on the website.',
  userContributionsTwo: 'All User Contributions must adhere to the content standards outlined in these Terms of Service. Any User Contribution you post on the website will be deemed non-confidential and non-proprietary. By providing any User Contribution on the website, you grant us, our affiliates, and service providers, as well as their and our respective licensees, successors, and assigns, the right to use, copy, modify, perform, display, distribute, and otherwise disclose such material to third parties for any purpose. You must own or control all rights in and to the User Contributions and have the right to grant the licenses set forth above to us, our affiliates, and service providers, as well as their and our respective licensees, successors, and assigns.',
  userContributionsThree: 'You understand and acknowledge that you are responsible for any User Contributions you submit or contribute, and you, not SmoothTravel, bear full responsibility for such content, including its legality, reliability, accuracy, and appropriateness. We are not responsible or liable to you or any third party for the content or accuracy of any User Contributions posted by you or any other user of the website.',
  userContributionsFour: 'This website may, at times, offer specific features and functionality (including but not limited to sweepstakes, contests, and other promotions), which may have additional rules, terms, and conditions governing your participation. If you choose to enter or participate, you will also be subject to any such rules, terms, and conditions applicable to these special features and functionalities.',

  contentStandards: '8. Content standards',
  contentStandardsOne: 'These content standards apply to all User Contributions and the use of interactive services. User Contributions must fully comply with all applicable national and international laws and regulations. In addition to the foregoing requirements, User Contributions must not:',
  contentStandardsTwo: '• Contain any defamatory, obscene, indecent, malicious, offensive, violent, hateful, provocative, or otherwise objectionable material.',
  contentStandardsThree: '• Promote sexually explicit or pornographic material, violence, or discrimination based on race, gender, religion, nationality, disability, sexual orientation, or age.',
  contentStandardsFour: '• Infringe on the patents, trademarks, trade secrets, copyrights, or other intellectual property or other rights of any other person.',
  contentStandardsFive: '• Violate the legal rights of others (including the right to publicity and privacy) or contain any material that may give rise to civil or criminal liability under applicable laws or regulations or otherwise be in conflict with these Terms of Service and our Privacy Policy.',
  contentStandardsSix: '• Deceive any person.',
  contentStandardsSeven: '• Promote any illegal activity or advocate, promote, or assist any unlawful act.',
  contentStandardsEight: '• Cause annoyance, inconvenience, or needless anxiety, or be likely to upset, embarrass, alarm, or annoy any other person.',
  contentStandardsNine: '• Impersonate any person or misrepresent your identity or affiliation with any person or organization.',
  contentStandardsTen: '• Involve commercial activities or sales, such as contests, sweepstakes, and other promotions, barter, or advertising (except with the permission of member agencies and preferred suppliers).',
  contentStandardsEleven: '• Give the impression that such contributions emanate from us or any other person or entity if this is not the case.',

  privacyRights: '9. Protection of User Personal Information and Privacy Rights',
  privacyRightsOne: 'Respecting user personal information and privacy rights is a fundamental policy of SmoothTravel. During your use of the SmoothTravel platform\'s products/services, SmoothTravel will employ relevant technological measures and other security measures to safeguard your personal information and privacy rights.',
  privacyRightsTwo: 'SmoothTravel is a platform targeted at high-quality service providers and suppliers in the industry. Therefore, it is not open to minors.',

  registration: '10. Registration of SmoothTravel Platform Account',
  registrationOne: 'Before you proceed to register and utilize the products/services offered by the SmoothTravel platform, you acknowledge that you must possess the legal capacity to engage in civil activities, as stipulated by the laws of the People\'s Republic of China, which are appropriate for your actions. Failure to have the requisite civil capacity may result in you and your guardian bearing all consequences as per the law.',
  registrationTwo: 'To access the products/services of the SmoothTravel platform, you can create a SmoothTravel platform account by registering directly on the platform using your mobile phone number.',
  registrationThree: 'Upon successful registration of a SmoothTravel platform account, you can also use it to log in to the products/services of certain partners authorized by SmoothTravel. However, when accessing the products/services of partners through the SmoothTravel platform account, ensure compliance with the account usage rules stipulated by the respective partners.',
  registrationFour: 'Registration of a SmoothTravel platform account is only permissible through official channels provided by SmoothTravel. Acquiring a SmoothTravel platform account through unauthorized means, including but not limited to purchasing, renting, borrowing, sharing, exploiting, or disrupting our registration system and/or other means without our permission, is strictly prohibited.',
  registrationFive: 'You possess a limited right to use the SmoothTravel platform account, restricted to logging in and checking contacts. It may not be transferred, rented, lent, sold, shared, or otherwise utilized by others, unless expressly authorized by law or SmoothTravel in writing.',
  registrationSix: 'In cases where there is evidence or SmoothTravel determines, based on the rules of the SmoothTravel platform, that you have improperly registered or misused multiple SmoothTravel platform accounts, the platform may take measures such as freezing or closing accounts, canceling orders, and refusing to provide services. Any losses incurred by the SmoothTravel platform and related parties due to your actions may also result in compensation liability.',
  registrationSeven: 'Super member privileges activated by you on the SmoothTravel platform will be linked to your SmoothTravel platform account. While you can exercise these privileges through the associated account, they cannot be enjoyed under other accounts.',

  accountSecurity: '11. Account Security',
  accountSecurityOne: 'Upon successful registration, as indicated by on-screen prompts, you will obtain a SmoothTravel platform account and can set the account password according to the password rules. Due to the unique nature of online services, SmoothTravel is not obligated to verify whether the username and password used to access the account belong to you. Anyone with a matching username and password stored in the database can log in to the SmoothTravel platform account and use SmoothTravel products/services. Therefore, handle your password with care and refrain from disclosing it to others. Failure to safeguard your password properly will make you responsible for all consequences arising or potentially arising from it. Except for SmoothTravel\'s fault, you are responsible for all actions under your account, including signing various agreements online, posting information, and purchasing goods and services.',
  accountSecurityTwo: 'You understand and agree that, to better use the various services of the SmoothTravel platform and ensure the security of your account, SmoothTravel may request you to complete real-name authentication in accordance with the rules of the SmoothTravel platform and relevant laws and regulations.',
  accountSecurityThree: 'Furthermore, for the convenience of providing customer service, when users dial the customer service number provided by the SmoothTravel platform using the contact mobile number confirmed during the order placement, the SmoothTravel platform will verify the user\'s identity by checking the order information and perform relevant operations based on the user\'s request. Therefore, safeguard your mobile phone and implement necessary security measures to prevent the leakage of order information. If the leakage of order information and other consequences are not caused by SmoothTravel\'s fault (for example, your mobile phone is stolen, or your mobile phone is not equipped with necessary encryption measures, leading to the unauthorized access and use of the order information on the SmoothTravel platform by others), even if you believe that the relevant actions were not performed by you, SmoothTravel will not assume any liability arising from this.',
  accountSecurityFour: 'You confirm and agree that you have the right and responsibility to enjoy the rights and assume the responsibilities for the activities, actions, and events conducted through your account in accordance with the law. You may not transfer your account, authorize others to use your account, or engage in transactions with others. If you discover that someone has used your SmoothTravel platform account without your authorization or there are other abnormalities with your account that prevent normal login and use, you need to reset your password following the password reset process provided by the SmoothTravel platform. During this process, SmoothTravel may request you to provide relevant information and/or supporting documents. Ensure that the information you provide is true and valid; otherwise, you may fail the verification process. If you discover any illegal use of a user account or security vulnerabilities, please immediately notify SmoothTravel through the contact information provided in this agreement.',

  advertising: '12. Advertising, Third-Party Links, and Commercial Marketing',
  advertisingOne: 'You acknowledge and agree that in the process of providing products/services, SmoothTravel platform reserves the right to display commercial advertisements (including advertising links) or any other types of commercial information in various ways (including but not limited to displaying advertisements at any location on the SmoothTravel platform, inserting advertisements in the content you upload or distribute), provided it complies with laws and regulations.',
  advertisingTwo: 'Subject to compliance with laws and regulations, SmoothTravel platform has the right to independently decide the form, duration, location, and content of advertising placement without any third-party intervention.',
  advertisingThree: 'The products/services of the SmoothTravel platform may provide links to other Internet websites or resources. SmoothTravel will fulfill the relevant obligations of advertisers in accordance with legal regulations. However, you understand and agree that these advertisers and advertising content are not controlled by SmoothTravel. You should judiciously assess the truthfulness and reliability of advertising information. Any actions or disputes between you and advertisers or sponsors through the products/services of the SmoothTravel platform are your responsibility for resolution. Except for the liability borne by the advertiser as stipulated by laws and regulations, SmoothTravel will not assume any additional liability but will provide necessary assistance as required by law. While accessing the SmoothTravel platform or using products/services, you agree to receive various types of information push services provided by the SmoothTravel platform, including but not limited to SMS push services and marketing information.',
  advertisingFour: 'Links to This Website and Social Media Features',
  advertisingFive: 'Only member institutions and preferred suppliers can link to our homepage following our brand and advertising guidelines.',
  advertisingSix: 'The website may provide certain social media features that enable you to:',
  advertisingSeven: '• Link from your own or certain third-party websites to certain content on this website.',
  advertisingEight: '• Send emails or other communications with certain content or links to certain content on this website.',
  advertisingNine: '• Display or appear to display parts of the content on this website on your own or certain third-party websites.',
  advertisingTen: 'You may only use these features in accordance with any additional terms and conditions provided by us related to such features, solely for the content they provide, and in a manner otherwise consistent with the additional terms and conditions provided by us. We may disable any or all social media features and any links at any time at our discretion without prior notice.',
  advertisingEleven: 'Links to Other Websites',
  advertisingTwelve: 'If this website contains links to other websites and resources provided by third parties, these links are provided for your convenience and information only, including links contained in advertisements, such as banner advertisements and sponsored links. We have no control over the content of these websites or resources and assume no responsibility for them or for any loss or damage that may arise from your use of them. If you decide to access any of the third-party websites linked to this website, you do so entirely at your own risk and subject to the terms and conditions of use for that website.',

  legalLiability: '13. Legal Liability',
  legalLiabilityOne: 'SmoothTravel explicitly states that it does not provide any type of guarantee, whether explicit or implied, with limitations on commercial implied guarantees, specific purposes, and non-violation of regulations.',
  legalLiabilityTwo: 'SmoothTravel is not responsible for corresponding breach liabilities for contract performance obstacles, defects, delays, or content changes due to the following reasons:',
  legalLiabilityThree: '• Force majeure factors such as natural disasters, strikes, riots, wars, government actions, judicial administrative orders, etc.;',
  legalLiabilityFour: '• Public service factors or third-party factors such as power supply failures, communication network failures, etc.;',
  legalLiabilityFive: '• In cases where SmoothTravel has fulfilled its management responsibilities in good faith, factors such as routine or emergency equipment and system maintenance, equipment and system failures, network information, and data security.',
  legalLiabilitySix: 'SmoothTravel does not bear any consequences for damages arising from users\' improper use of network membership services, illegal use of services, or changes in information provided by users to the SmoothTravel platform, as SmoothTravel has already pointed out the possibility of such damages and requested users to take preventive measures.',
  legalLiabilitySeven: 'In any case, SmoothTravel is not liable for any indirect damages (including loss of goodwill, loss of profits, loss of income, business interruption, loss of contractual opportunities, loss of expected profits, and other indirect, incidental, punitive losses).',
  legalLiabilityEight: 'If you engage in actions on the SmoothTravel platform that violate this agreement or other individual service agreements or rules, SmoothTravel platform may take measures such as deducting points from your account, restricting participation in marketing activities, suspending the provision of some or all services, canceling orders, etc., in accordance with the corresponding rules. If your actions constitute a fundamental breach, SmoothTravel platform may terminate the provision of services. If your actions cause SmoothTravel to suffer losses (including direct economic losses and indirect economic losses), or if any third party claims, or if penalties are imposed by relevant administrative authorities, you shall bear all compensation responsibilities.',
  legalLiabilityNine: 'If anyone claims that your actions have harmed the rights and interests of third parties, leading to lawsuits or other disputes for SmoothTravel or third parties providing products/services to the SmoothTravel platform, you agree to defend SmoothTravel and/or the above-mentioned third parties, and SmoothTravel reserves the right to defend itself at its own expense and seek compensation from you.',

  supervision: '14. Supervision and Enforcement; Termination; Our Rights:',
  supervisionOne: '• We reserve the right to independently decide to remove or refuse to post any user contributions for any reason. However, we are not obligated to review user contributions before or after they are posted and cannot guarantee the removal or correction of materials that violate these terms of service. Therefore, we assume no responsibility for any actions or inactions regarding the transmission, communication, or content provided by any user or third party. We are not liable or responsible for the enforcement or non-enforcement of the activities described in this section for anyone.',
  supervisionTwo: '• Take any necessary or appropriate actions, including processing any user contributions if we believe such contributions violate the terms of service (including content standards), infringe upon the intellectual property or other rights of any person or entity, threaten the personal safety of website users or the public, or may bring liability to SmoothTravel.',
  supervisionThree: '• Disclose your identity or other information about you in response to any third party\'s claim that material posted by you violates its rights (including intellectual property or privacy rights).',
  supervisionFour: '• Take appropriate legal action, including but not limited to referral to law enforcement, against any illegal or unauthorized use of this website.',
  supervisionFive: '• Terminate or suspend your access to all or part of the content on this website without any reason, including but not limited to a violation of these terms of service.',
  supervisionSix: 'In addition to the above, we reserve the right to fully cooperate with any law enforcement agency or court order that requests or instructs us to disclose the identity or other information of anyone posting material on this website. You waive and protect SmoothTravel and its affiliates from any liability for any claims arising from actions taken by SmoothTravel and/or any such party or law enforcement agency in connection with any investigation conducted by SmoothTravel and/or such party or law enforcement agency.',

  copyrightInfringement: '15. Copyright Infringement',
  copyrightInfringementOne: 'Any copyright owner or their authorized representative who believes that any content on this website infringes their copyright may submit a notification in accordance with copyright law and provide the following written information to the designated copyright agent at SmoothTravel:',
  copyrightInfringementTwo: '1)A physical or electronic signature of the owner of the proprietary rights allegedly infringed, or their authorized representative;',
  copyrightInfringementThree: '2)Identification of the copyrighted work claimed to have been infringed, or if multiple copyrighted works on a single online site are covered by a single notification, a representative list of such works on that site;',
  copyrightInfringementFour: '3)Material that is claimed to be infringing or to be the subject of infringing activity and should be removed or access to which should be disabled, along with sufficient information to enable SmoothTravel to locate the material;',
  copyrightInfringementFive: '4)Reasonable information to allow SmoothTravel to contact you, such as address, telephone number, and email (if available);',
  copyrightInfringementSix: '5)A statement that the complaining party has a good-faith belief that the use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law;',
  copyrightInfringementSeven: '6)A statement that the information in the notification is accurate, and, under penalty of perjury, that the complaining party is authorized to act on behalf of the owner of the proprietary rights allegedly infringed.',
  copyrightInfringementEight: 'If you believe that the content you provided to the website does not infringe any copyright, or if you have the authority to publish and use the content as the copyright owner or their authorized representative under the law, you may send a counter-notification to the copyright agent containing the following information:',
  copyrightInfringementNine: '1)Your physical or electronic signature;',
  copyrightInfringementTen: '2)Identification of the content that has been removed or access to which has been disabled, and the location at which the content appeared before it was removed or disabled;',
  copyrightInfringementEleven: '3)A statement that you have a good-faith belief that the content was removed or disabled as a result of mistake or misidentification;',
  copyrightInfringementTwelve: '4)Your name, address, telephone number, and email address, with a statement that you consent to the jurisdiction of the High People\'s Court of Xuhui District, Shanghai, and a statement that you will accept service of process from the person who provided the infringement notice.',
  copyrightInfringementThirteen: 'If the copyright agent receives a counter-notification, SmoothTravel may send a copy to the original complainant, notifying them that they may replace the removed content or cease disabling it within ten (10) business days. SmoothTravel, at its sole discretion, will restore the removed content or reinstate access to it within ten (10) to fourteen (14) business days after receiving the counter-notification unless the copyright owner seeks a court order against the content provider.',

  legal: '16. Legal',
  legalOne: 'The concepts and definitions involved in this agreement are consistent with those in the laws of the People\'s Republic of China. Disputes arising from any business cooperation or the use of any services through the SmoothTravel platform can be resolved through negotiation; if negotiation fails, either party may submit the dispute to the Shanghai Arbitration Commission for arbitration.',
  legalTwo: 'For disputes related to the SmoothTravel platform, you acknowledge and fully agree that for simple monetary payment cases involving clear facts, well-defined rights and obligations, minor disputes, and a claim amount between RMB 50,000 and 100,000, the simplified arbitration procedure shall be used, with a single-instance final decision. The specific details of the simplified arbitration procedure, including the adjudicating organization, the time frame, the manner of proceedings, and the final decision for cases heard under the simplified arbitration procedure, shall be governed by the "xxxxx" issued by xxxx.',

  websiteChanges: '17. Website Changes',
  websiteChangesOne: 'We may regularly update the content on this website, but it may not always be complete or up-to-date. At any time, any material on this website may become outdated, and we are not obligated to update such material.',
  websiteChangesTwo: 'Information about You and Your Website Visits',
  websiteChangesThree: 'All information collected on this website is subject to our privacy policy. By using this website, you agree that all actions we take regarding your information comply with the privacy policy.',
  websiteChangesFour: 'Online Booking Requests',
  websiteChangesFive: 'Certain features of this website may facilitate members and/or member institutions in making reservations with preferred suppliers. When you submit a booking request through this website, members are hereby notified that SmoothTravel will provide your booking information (including contact information, payment information, and booking details) to the applicable member institutions and relevant service providers in accordance with our privacy policy. This is done to allow these parties to fulfill the requested booking and process your payment; SmoothTravel does not collect credit card fees or handle payments. The booking is only considered final when the applicable member institution\'s global distribution system accepts and confirms the reservation and processes the payment in writing.',

  paymentDisputes: '18. Payment Disputes, Cancellations, and/or Service Issues',
  paymentDisputesOne: 'Must be addressed to the applicable member institution and/or preferred supplier. Additional terms and conditions of the preferred supplier may apply. Prices and availability are subject to change without notice. For international travel, due to currency exchange rate fluctuations, the quoted amount may differ from what is charged to your credit card. Conversion fees from your bank and/or credit card company may also appear on your bill.',
  paymentDisputesTwo: 'As the independent operator of the platform, SmoothTravel does not own or control member institutions and preferred suppliers. SmoothTravel is not responsible for their actions or inactions, nor for any harm, loss, expense, or liability arising from them. SmoothTravel is not the seller or provider of any travel products or services booked or reserved through this website. SmoothTravel hereby disclaims all warranties, whether express or implied, statutory, or otherwise, including but not limited to the availability, satisfaction, merchantability, or fitness for a particular purpose of any such travel products or services. The foregoing does not affect any warranties that cannot be excluded or limited under applicable law.',

  contactInformation: '19. Contact Information',
  contactInformationOne: 'If you have any questions or concerns regarding this agreement or the use of SmoothTravel platform\'s products/services (including inquiries or complaints), we have provided the following feedback channel specifically for you, aiming to provide satisfactory solutions:',
  contactInformationTwo: "• Email Channel: support{'@'}smoothtravel.com",

  disclaimer: '20. Disclaimer',
  disclaimerOne: 'SmoothTravel is guided by the Shanghai Cultural and Tourism Quality Service and Monitoring Center and operated by AVIAREPS China Ltd. Specific product services will be provided by qualified service providers. By accessing, booking, or using the products or services of member units on the SmoothTravel platform ("Services"), the user ("User," "Member," or "You") is deemed to have accepted the following disclaimer. Please read the following carefully, especially the bolded text. If you do not agree with any of the following, please immediately stop accessing/using this website or any other services provided by related mobile applications or software.',
  disclaimerTwo: 'You fully agree and acknowledge that SmoothTravel is not responsible for any defects or issues in the travel service projects booked by users. However, SmoothTravel will make efforts to assist users in negotiating or seeking arbitration services with relevant travel service providers.',
  disclaimerThree: 'You fully understand and agree that, as SmoothTravel is a platform for information exchange, the services provided by the partner you choose to cooperate with will be the result of your mutual agreement. You fully understand and waive any responsibility on the part of SmoothTravel for the outcome of such services.',
  disclaimerFour: 'For your convenience, SmoothTravel may provide ratings, recommendations, or risk warnings regarding the reliability of products. However, SmoothTravel does not guarantee the accuracy and completeness of such ratings, recommendations, or risk warnings and does not assume any responsibility for the content and services of recommended websites.',
  disclaimerFive: 'You understand and agree that any graphics, images, or personal opinions published through the services provided by SmoothTravel represent the personal views, concepts, and thoughts of the content provider or service user and do not represent the views or claims of SmoothTravel. You are responsible for judging and bearing all risks of encountering unpleasant or inappropriate content during the enjoyment of online services, and SmoothTravel assumes no responsibility.',
  disclaimerSix: 'SmoothTravel serves as a platform for the supply and demand of the tourism industry, providing a platform for travel service providers, third-party agents (including but not limited to airlines, accommodations, tourism products, attraction tickets, etc.) to promote their products and services and facilitating the reservation of travel service resources between enterprises.',
  disclaimerSeven: 'You fully understand and agree that, when purchasing existing or new products and services from partner enterprises through SmoothTravel, you should make timely payments after booking. Until you have fully paid for the booked products or services, you have not completed the purchase. You fully understand and agree that any additional expenses, losses, or inventory issues caused by real-time updates or changes in the price, cabin, quantity, or inventory of the aforementioned products or services are your responsibility, and SmoothTravel assumes no responsibility. You fully understand and agree that in the event of payment failure due to network interruptions, incorrect bank feedback information, or other reasons not attributable to SmoothTravel, you will bear the consequences, and SmoothTravel assumes no responsibility.',
  disclaimerEight: 'Please save or back up your text, images, and other information in a timely manner. You fully understand and agree that due to limited storage equipment, equipment malfunctions, equipment updates, equipment attacks, or other reasons, there is a risk of deletion, damage, loss, or irreparable of the information or data stored through the network service, and you bear all risks, and SmoothTravel assumes no responsibility.',
  disclaimerNine: 'SmoothTravel does not guarantee that network services will meet your requirements, nor does it guarantee that network services will not be interrupted. No guarantees are made regarding the timeliness, security, accuracy of network services.',
  disclaimerTen: 'SmoothTravel does not guarantee the accuracy and completeness of external links provided for user convenience. SmoothTravel is not responsible for the content on any web pages not under its actual control, which external links may point to.',
  disclaimerEleven: 'SmoothTravel is not responsible for any interruption or other defects in network services caused by force majeure or reasons beyond SmoothTravel\'s control. However, efforts will be made to minimize the losses and impacts caused by such interruptions to users.',
  disclaimerTwelve: 'You understand that we cannot guarantee that the internet or files available for download on this website are free from viruses or other destructive code. You should be responsible for implementing sufficient procedures and checkpoints to satisfy your specific anti-virus protection and data input/output accuracy requirements. We will not be liable for any loss or damage caused by a distributed denial-of-service attack, viruses, or other technologically harmful materials that may infect your computer equipment, computer programs, data, or other proprietary materials due to your use of this website or any services or items obtained through this website, or any material downloaded from this website or any linked website.',
  disclaimerThirteen: 'Your use of this website, its content, and any services or products obtained through this website is at your own risk. This website, its content, and any services or products obtained through this website are provided on an "as-is" and "as-available" basis, without any warranties, either express or implied. SmoothTravel or any related parties do not make any warranties or representations regarding the completeness, security, reliability, quality, accuracy, or availability of this website, its content, or any services or products obtained through this website. Without limiting the foregoing, SmoothTravel or any related parties do not make any warranties or representations that this website, its content, or any services or products obtained through this website are accurate, reliable, error-free, or uninterrupted, that defects will be corrected, or that our website or the servers that make it available are free of viruses or other harmful components, or that this website or any services or products obtained through this website will meet your needs or expectations.',
  disclaimerFourteen: 'SmoothTravel hereby disclaims all warranties, whether express or implied, statutory, or otherwise, including but not limited to merchantability, non-infringement, and fitness for a particular purpose.',
  disclaimerFifteen: 'The above disclaimer is consistent with the interpretation of the laws of the People\'s Republic of China. In case of any disputes or conflicts with legal terms, you and SmoothTravel unanimously agree to submit to the jurisdiction of the court. If any service terms conflict with the law, these terms will be reinterpreted as closely as possible, while other terms will remain legally effective and binding on users. In the event of disputes arising from the interpretation of this disclaimer and the booking of any products through the SmoothTravel platform, you agree to accept arbitration by the Shanghai Arbitration Commission.',

  limitedLiability: '21. Limited Liability',
  limitedLiabilityOne: 'In no event shall SmoothTravel, its affiliated companies, or their respective employees, agents, officers, or directors be liable for any damages, whether in any legal theory, arising out of or in connection with your use or inability to use this website, any websites linked to it, any content on this website or other websites, and any services or products obtained through this website or other websites. This includes but is not limited to direct, indirect, special, incidental, consequential, or punitive damages, including but not limited to personal injury, pain and suffering, emotional distress, loss of income, loss of profits, loss of business or anticipated savings, loss of use, loss of goodwill, and loss of data, whether caused by tort (including negligence), breach of contract, or otherwise, even if foreseeable. The foregoing does not affect any liability that cannot be excluded or limited under applicable law.',

  indemnification: '22. Indemnification',
  indemnificationOne: 'You agree to indemnify, defend, and hold harmless SmoothTravel, its affiliated companies, and their respective directors, officers, employees, agents, successors, and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys\' fees) arising out of or in connection with your violation of these Terms of Service or your use of this website, including but not limited to your user contributions, any use of the content on this website, or the use of any services, products, and/or information obtained from this website.',

  waiverAndSeverability: '23. Waiver and Severability',
  waiverAndSeverabilityOne: 'SmoothTravel\'s failure to assert any right or provision under these Terms of Service shall not constitute a waiver of such right or provision, nor of any other rights or provisions. Any waiver of any term or condition set forth in these Terms of Service shall not be deemed as a further or continuing waiver of such term or condition or a waiver of any other term or condition. If, for any reason, any provision of these Terms of Service is found to be invalid, illegal, or unenforceable under the judgment of a court or other arbitration body with jurisdiction, that provision shall be deemed severed or limited to the minimum extent necessary, so that the remaining provisions of the Terms of Service remain in full force and effect.',

  entireAgreement: '24. Entire Agreement',
  entireAgreementOne: 'The Terms of Service and our Privacy Policy constitute the sole and entire agreement between you and SmoothTravel regarding this website and supersede all prior and contemporaneous understandings, agreements, statements, and warranties, whether written or oral, relating to this website.',
  entireAgreementTwo: 'Notwithstanding the foregoing or any contrary provisions in this document, for users representing member institutions or SmoothTravel preferred suppliers, existing or future additional written agreements may exist (including but not limited to the SmoothTravel Member Agreement, Hotel Booking Program Agreement, and/or SmoothTravel Preferred Supplier Agreement). These written agreements remain fully effective and do not modify or alter these additional written agreements.',
  entireAgreementThree: 'In the event of a conflict between these Terms of Service and any other written agreements with SmoothTravel, SmoothTravel has the right, in its reasonable discretion, to determine which terms control.',

  /**
   * 以下是之前表里的数据
   */
  resourceCenterIntro: 'If you are seeking to stay updated on the latest China outbound travel trends and industry dynamics, or are in need of general analytical insights and research into China’s outbound tourism market, SmoothTravel’s Resource Center is equipped with comprehensive and timely China outbound travel research including:<br/><br/>' +
    '✓ latest China outbound travel industry reports <br/>' +
    '✓ most recent data on China’s outbound tourism industry<br/>' +
    '✓ information on China’s national tourism policies and regulations <br/>' +
    '✓ updates on latest China outbound market trends <br/><br/>' +
    "If you encounter any problems while browsing through our resources or have materials related to the tourism industry to share, feel free to contact our Resource Center team anytime. Share your thoughts or materials with us at: <a href=\"mailto:resourcecenter{'@'}smoothtravel.com\">resourcecenter{'@'}smoothtravel.com</a>",
  'smoothTravelProvidesAComprehensiveAndEffectiveOnlineTravelExpertTrainingForDestinationsAndTourismIndustryPartnersWorldwide.': 'SmoothTravel provides a comprehensive and effective online travel expert training for destinations and tourism industry partners worldwide.',
  startLearning: 'Start learning',
  takeTheExamDirectly: 'Take the exam directly',
  resourceCenter: 'Resource Center',
  expertCertificatesIveObtained: 'Expert certificates I\'ve obtained',
  allMaterials: 'All materials',
  ongoingTrainings: 'Ongoing trainings',
  allTrainings: 'All trainings',
  expertTraining: 'Expert training',
  chineseOutboundPartners: 'Chinese Outbound Partners',
  insuranceintroduction: 'Insurance',
  introductiontoarbitration: 'Arbitration ',
  fundsupervision: 'Payment Guarantees',
  marketsurvey: 'China Travel Market Research',
  cooperativepartner: 'Key Stakeholders',
  insurance: 'Insurance',
  arbitration: 'Arbitration',
  viewmore: 'View more',
  servicesthree: 'Through the study of the data center, you can obtain the corresponding expert qualification by completing the expert assessment here, show your professional level, and better obtain the recognition of customers.',
  noexpertcertificatehasbeenobtained: 'No expert certificate has been obtained',
  pleaseenteramessage: 'Please enter a message',
  theexpert: 'The expert training exam is divided into several parts. Only if all the questions in the previous part are answered correctly can the questions in the next part be saved or continued. All correct answers will earn an expert certificate in this category.',
  progress: 'Progress：',
  singlechoice: 'Single choice',
  nextquestion: 'Next question',
  multipleChoice: 'multipleChoice',
  submit: 'submit',
  continuetheexamination: 'Continue the examination',
  startheexamination: 'Start the examination',
  alreadypassed: 'Already passed',
  /**
   * 表里数据结束
   */
}
